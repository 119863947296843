import { clientId } from './types';
import { LoginType, Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";

const redirectUri = window.location.origin ||
    (window.location.protocol + '//' + window.location.hostname +
        (window.location.port ? ':' + window.location.port : ''));

Providers.globalProvider = new Msal2Provider({
    clientId: clientId,
    scopes: [
        'Calendars.Read',
        'email',
        'Mail.ReadBasic',
        'offline_access',
        'openid',
        'People.Read',
        'People.Read.All',
        'profile',
        'Sites.Read.All',
        'User.Read',
        'User.Read.All', 
        'User.ReadBasic.All'
    ],
    loginType: LoginType.Redirect,
    redirectUri: redirectUri
});

export const globalAuthProvider = Providers.globalProvider;